import { CommentBodyType } from '@icepanel/platform-api-client'

const descriptions: Record<CommentBodyType, string> = {
  idea: 'Add ideas and thoughts',
  inaccurate: 'Mark something as inaccurate\nIncreases inaccuracy score if not resolved',
  'new-idea': 'New comment',
  'new-inaccurate': 'New comment',
  'new-question': 'New comment',
  question: 'Ask questions from your team'
}

export default descriptions
